import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { Auth0WrapperProps } from './type';
import { useDispatch, useSelector } from 'react-redux';
import { addAuth0Interceptors } from '../../../../../helpers/axiosInterceptors';
import { fetchActiveUser, getActiveUser } from '../../../../../services/userV2';
import { useLocation, useHistory } from 'react-router-dom';
import { fetchQuickbooksActiveAccount } from 'services/integrations/quickbooks/redux';
import { fetchShippingIntegrationConnections } from 'services/integrations/shipping/redux';
import { fetchSettingsCompanies } from 'services/settings/company';
import { fetchPaymentTerms } from 'services/paymentTerms';
import { fetchXeroActiveAccount } from 'services/integrations/xero';
import { fetchSellwareActiveAccount } from 'services/commerce/redux';

const Auth0Handler: React.FC<Auth0WrapperProps> = ({ children }) => {
  const {
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
    handleRedirectCallback,
    logout,
  } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [accessTokenLoaded, setAccessTokenLoaded] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const { user } = useSelector(getActiveUser);

  useEffect(() => {
    (async () => {
      try {
        if (params.has('error')) {
          await logout({
            logoutParams: {
              returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL,
            },
          });
        } else if (params.has('code')) {
          await handleRedirectCallback(window.location.href);
          const accessToken = await getAccessTokenSilently();
          addAuth0Interceptors(accessToken, logout);
          dispatch(fetchActiveUser() as any);
          // @ts-ignore
          dispatch(fetchQuickbooksActiveAccount());
          // @ts-ignore
          dispatch(fetchShippingIntegrationConnections());
          // @ts-ignore
          dispatch(fetchSettingsCompanies());
          // @ts-ignore
          dispatch(fetchPaymentTerms());
          // @ts-ignore
          dispatch(fetchXeroActiveAccount());
          // @ts-ignore
          dispatch(fetchSellwareActiveAccount());
          // set the access token
          setAccessTokenLoaded(true);
          const redirectTo = sessionStorage.getItem('redirectTo');
          if (redirectTo) {
            history.push(redirectTo);
          }
        } else {
          const fullURL = `${location.pathname}${location.search}${location.hash}`;
          sessionStorage.setItem('redirectTo', fullURL);
          loginWithRedirect({
            authorizationParams: {
              redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
            },
          }).then();
        }
      } catch (error) {
        console.error('Error in Auth0Handler', error);
        await logout({
          logoutParams: { returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL },
        });
      }
    })();
  }, []);

  if (isLoading || !accessTokenLoaded || !user) {
    return (
      <NetworkSpinnerWrapper
        isLoading={isLoading}
        display="flex"
        flexDirection="column"
        overflow="hidden"
        flexGrow={1}
        zIndex={2}
      >
        {/* Empty fragment used as a placeholder for required children props */}
        <></>
      </NetworkSpinnerWrapper>
    );
  }

  return <>{children}</>;
};

export default Auth0Handler;
